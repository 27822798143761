import React, { useState, useRef } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { get_autocomplete_request_body } from '../helper.js'
import axios from 'axios';

const AutocompleteSubMultiSelect = (props) => {

    const {apiSubModel,singleChangeHandler,autoCompleteClass,makeDisable,useLogUrl} = props
    const [searchValue, setSearchValue] = useState('')
    const [displayValue, setDisplayValue] = useState({'memo': ''})
    const [options, setOptions] = useState([])
    const user = useSelector((state)=> state.user)
    const ref = useRef()

  const handleInputChange = async (event, newValue) => {
    if(event){
      setDisplayValue({'memo': newValue})
      if(newValue===''){
        singleChangeHandler('')
      }
      if(newValue.length >= 3){
          try {
              setSearchValue(newValue);
              const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiSubModel + '/search'
              const url_log = process.env.REACT_APP_LOCAL_BACKEND_LOG_URL + apiSubModel + '/search'
              const config = {
                      headers: {'Authorization': 'Bearer ' + user.token},
                      params: {}
                  }
              let requestBody=get_autocomplete_request_body(searchValue)
              const response = useLogUrl ? await axios.post(url_log,requestBody,config) : await axios.post(url,requestBody,config)
              setOptions(response.data.result) // Assumendo che l'API restituisca un array di oggetti { id, displayname }
          } catch (error) {
              console.error('Errore nel recupero delle opzioni:', error);
          }
      }else{
        setOptions([])
      }
    }
  }

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setDisplayValue({'memo': null})
      singleChangeHandler(newValue.uuid)
    }
  };

  return (
    <div>
      <Autocomplete ref={ref}
        options={options}
        className={'mb-2'}
        getOptionLabel={(option) => option.memo?option.memo:''}
        value={displayValue}
        onChange={handleOptionSelect}
        onInputChange={handleInputChange}
        freeSolo={true}
        disabled={makeDisable}
        renderInput={(params) => (
          <TextField key={params.uuid} className={autoCompleteClass}
            {...params}
            placeholder="Cerca un elemento..."
            variant="standard"
          />
        )}
      />
    </div>
  );
};

export default AutocompleteSubMultiSelect
