import React, { useEffect, useState,  useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logIn, saveLoginInLocalStorage, setCode } from '../app/store'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { generate_rnd_string, sha256_and_base64_encode } from '../helper'
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {

    const user = useSelector((state)=> state.user)
    const [logginIn,setLogginIn] = useState(false)
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()

    /* Refresh token logic when near expiration done in App.js */

    const exchangeReceivedCodeForToken = useCallback(async(code) => {
      const url = process.env.REACT_APP_LOCAL_AUTH_GET_TOKEN_URL
      const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params: {}
      }
      const postData = {
        'grant_type': 'authorization_code',
        'client_id': 'aaaui',
        'code': code,
        'code_verifier': user.code,
        'redirect_uri': process.env.REACT_APP_LOCAL_FRONTEND_URL,
      }
      try{
        const response = await axios.post(url,postData,config)
        if(response.data.error){
          if(response.data.error !== 'invalid_request'){
            toast.error(response.data.error)
          } else {
            console.log('Richiesta invalida in fase di Login (pulire la stringa dell\'url dai vecchi parametri)')
          }
        } else {
          return response.data
        }
      } catch (error){
        toast.error('Errore di rete in fase di Login')
      }
      return null
    },[user.code])

    // After login backend returns a code and other GET params, make axios call to get actual token (STEP 2)
    useEffect(()=>{
      if(user.code && !user.logged_in){
        if(searchParams && searchParams.get('code')) {
          setLogginIn(true)
          exchangeReceivedCodeForToken(searchParams.get('code')).then(
            (data)=>{
              setLogginIn(false)
              if(data && data.access_token){
                dispatch(logIn(
                  { token: data.access_token, refreshToken: data.refresh_token, expires: data.expires_in }
                  ))
                dispatch(saveLoginInLocalStorage())
              }
            }
          )
        } else if (searchParams && searchParams.get('error')) {
          console.log('Login error: ' + searchParams.get('error'))
          toast.error('Nome utente o password errati')
        }
      }
    },[searchParams,user.logged_in,user.code,exchangeReceivedCodeForToken,dispatch])

    // Make redirect to login UI with all parameters set (STEP 1)
    const handleLogin = async () => {
        let code = generate_rnd_string(6),
            state = '1234',
            encoded_code = await sha256_and_base64_encode(code)
        dispatch(setCode(code))
        document.location.href = process.env.REACT_APP_LOCAL_AUTH_URL + '?client_id=aaaui&response_type=code&state=' + state +
          '&redirect_uri=' + process.env.REACT_APP_LOCAL_FRONTEND_URL + '&scope=&code_challenge=' + encodeURIComponent(encoded_code) + '&code_challenge_method=S256'
    }

  return (
    <div className='' style={{marginTop: 0}}>
      <ToastContainer
          className='toast-lowered'
          position='top-center'
          theme='dark'
        />
      <div className='hero d-flex justify-content-center align-items-center' style={{paddingBottom: 50}}>
        {!logginIn && <button className='btn mcr-btn' onClick={handleLogin}>Login</button>}
      </div>
    </div>
  )
}

export default Login