import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get_field_apimodel, get_html_field_type, get_field_value, get_checked, get_html_field_classname, get_html_fieldlabel_classname, get_post_object_field_value, get_field_isvalid,check_field_validation, get_final_ui_structure, get_error_message_by_code} from '../helper'
import AutocompleteInput from '../fields/Autocomplete'
import MultiAutoComplete from '../fields/MultiAutoComplete'
import { setTriggerReload } from '../app/store'
import useWindowSize from '../hooks/Windowsize'
import useScrollbarWidth from '../hooks/ScrollbarWidth';

const Newform = (props) => {

    const {nomeSing,url,apiModel,enableSave,useLogUrl} = props

    const [loading1, setloading1] = useState(false)
    const [,setSaved] = useState(false)
    const [loadErr, setLoadErr] = useState(null)
    const [,setSaveErr] = useState(null)
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [objectData, setObjectData] = useState({})
    const [modifiedFields, setModifiedFields] = useState({})
    const [committed, setCommitted] = useState(false)
    const [validation, setValidation] = useState([])
    const [rendered, setRendered] = useState(false)
    const [finalRenderingStructure, setFinalRenderingStructure] = useState([])

    const structureData = useSelector((state)=> state.dataStructure.structure)
    const uiStructure = useSelector((state)=> state.uiStructure.structure)
    const loadedCount = useSelector((state)=> state.loadedCount)
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()

    const {id} = useParams()
    const navigate = useNavigate()

    const windowSize = useWindowSize()
    const scrollbarWidth = useScrollbarWidth()

    // For slide animation to be displayed
    useEffect(()=>{setRendered(true)},[])

    useEffect(()=>{
      let finalUIstructure = get_final_ui_structure(uiStructure,structureData)
      setFinalRenderingStructure(finalUIstructure)
    },[structureData,uiStructure])

    useEffect(()=>{
      setValidation([])
      let validated=check_field_validation(structureData,objectData)
      setValidation(validated)
    },[structureData,objectData])

    useEffect(() => {
        setloading1(true)
        setLoadErr(null)
        const getData = async() => {
          const url = process.env.REACT_APP_LOCAL_BACKEND_URL+ apiModel + '/create/1';
          const url_log = process.env.REACT_APP_LOCAL_BACKEND_LOG_URL+ apiModel + '/create/1';
          const config = {
            headers: {'Authorization': 'Bearer ' + user.token},
            params: {}
          }
          try{
            const response = useLogUrl ? await axios.get(url_log,config) : await axios.get(url,config)
            const recdata = response.data.result[0]
            if(recdata){
              if(recdata.committed){
                setCommitted(true)
              }else{
                setCommitted(false)
              }
              setObjectData(recdata)
            }
            setloading1(false)
          } catch(error){
            if(error.response.data.error.code){
              setLoadErr(get_error_message_by_code(error.response.data.error.code))
              toast.error(get_error_message_by_code(error.response.data.error.code))
            } else {
              setLoadErr('Errore di rete generando l\'oggetto!')
              toast.error('Errore di rete generando l\'oggetto!')
            }
            setloading1(false)
          }
        }
        getData()
      },[setloading1,id,apiModel,useLogUrl,user.token]
      )

      const handleChange = (value, field) => {
        let objectData2 = objectData
        objectData2[field.name] = get_post_object_field_value(field, value)
        let modifiedFields2 = modifiedFields
        modifiedFields2[field.name] = objectData2[field.name]
        if(!committed){
          objectData2['committed']=1
          modifiedFields2['committed']=1
          setCommitted(true)
        }
        setObjectData({ ...objectData2 })
        setModifiedFields({ ...modifiedFields2 })
      }

    const handleSave = () => {
      let validForm = true
      for (let k in validation) {
        if (!validation[k].valid) {
          validForm = false
          toast.error(validation[k].message)
        }
      }
      if(!validForm) return false
      if(objectData.uuid){
        setSaved(false)
        setSaveErr(null)
        setBtnDisabled(true)
        const saveNow = async() => {
            const url = process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/save';
            const url_log = process.env.REACT_APP_LOCAL_BACKEND_LOG_URL + apiModel + '/save';
            const config = {
              headers: {'Authorization': 'Bearer ' + user.token},
            }
            let data = modifiedFields
            data.uuid=objectData.uuid
            const postData = [data]
            try {
              const response = useLogUrl ? await axios.post(url_log,postData,config) : await axios.post(url,postData,config)
              if(response.data.result === true){
                setSaved(true)
                toast.success("Salvato con successo")
                setTimeout(()=>{
                  setSaved(false)
                  setBtnDisabled(false)
                },2000)
                dispatch(setTriggerReload(loadedCount.triggerReload+1))
                return true
              } else {
                setSaveErr(response.data.error)
                toast.error(response.data.error)
                setTimeout(()=>{
                    setSaveErr(null)
                    setBtnDisabled(false)
                },2000)
              }
              setModifiedFields({})
              return false
            } catch(error){
                if(error.response.data.error.code){
                  setSaveErr(get_error_message_by_code(error.response.data.error.code))
                  toast.error(get_error_message_by_code(error.response.data.error.code))
                } else {
                  setSaveErr('Errore di rete al salvataggio!')
                  toast.error('Errore di rete al salvataggio!')
                }
                setTimeout(()=>{
                    setSaveErr(null)
                    setBtnDisabled(false)
                },2000)
                return false
            } 
        }
        return saveNow()
      }
    }

    const handleSaveAndBack = () => {
      if(handleSave()){
        handleBack()
      }
    }

    const handleBack = () => {
      setRendered(false)
      setTimeout(
        ()=>{navigate(process.env.REACT_APP_LOCAL_SUBFOLDER + url)},500
      )
    }

  return (
    <>
    <div className={"overlay"+(rendered ? ' visible' : '')}>
    <div className="container-fluid px-0">
      
      <div className={'bg-dark form-header'+(rendered ? ' visible' : '')+ ' pt-4 pb-3'}>
        <div className="row">
            <div className="col-12 col-md-2 col-lg-3 col-xl-4">
            <button className='btn mcr-btn btn-with-icon icon-back ms-4 ms-md-2 mb-2' onClick={() => {handleBack()}}><span className="material-symbols-rounded">arrow_back</span></button>
            </div>
            <div className="col-12 col-md-8 col-lg-6 col-xl-4 pb-2">
              <h3 className='text-even text-start text-md-center ms-4 ms-md-0'>Nuovo {nomeSing}</h3>
            </div>
        </div>
      </div>

        {loading1 && <h3 className='h3 text-even mt-5 pt-5 ms-3'>Caricamento...</h3>}
        {!loading1 && loadErr && <h3 className='h3 text-even mt-5 pt-5 ms-3'>{loadErr}</h3>}
        {!loading1 && objectData && structureData && <div className='w-100 px-2'>

        {!loadErr && <div className={"overlay-top-buttons-container py-1 px-2"} style={rendered ? {width: 'calc(100vw - '+(400+scrollbarWidth)+'px)', maxWidth: (990-scrollbarWidth )+'px'}: {}} >
            <div className='row'>
            <div className="col-12 text-end">
              {!loadErr && enableSave && <button className='btn mcr-btn btn-with-icon ms-0' title="Salva" onClick={() => { handleSave() }} disabled={btnDisabled}><span className="material-symbols-rounded">save</span></button>}
              {!loadErr && enableSave && <button className='btn mcr-btn success btn-with-icon ms-0' title="Salva e chiudi" onClick={() => { handleSaveAndBack() }} disabled={btnDisabled}><span className="material-symbols-rounded">done</span></button>}
            </div>
            </div>
          </div>}

            <div className='row mb-3' style={windowSize!=='sm'?{marginTop: '130px'}:{marginTop: '170px'}}>
            {
                !loadErr && finalRenderingStructure.length && finalRenderingStructure.map((field, idx) => {
                  if(field.section)return <React.Fragment key={idx}>
                    <div className='col-12'>
                      <label className='form-label text-even mt-3 h4 mt-4'>{field.name}</label>
                      <hr className='text-even mt-0'/>
                    </div>
                    </React.Fragment>
                  else
                  return <React.Fragment key={idx}>
                    <div className={"col-12 col-md-"+(field.format?field.format:'6')+" " +(get_html_fieldlabel_classname(field)==='d-none'?'d-none':'')}>
                      <label
                        className={get_html_fieldlabel_classname(field,true)}>
                        <b>
                          {(field.label ? field.label.charAt(0).toUpperCase()+field.label.slice(1) : field.name.charAt(0).toUpperCase()+field.name.slice(1)) + (field.name === 'price' ? ' €' : '')}
                        </b>
                      </label>
                      {!['uuid','uuid[]','longtext'].includes(field.type) && <input
                      readOnly={field.name==='created'} 
                      name={field.name}
                      required={field.required}
                      className={get_html_field_classname(field,true) + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      type={get_html_field_type(field)}
                      value={get_field_value(field, objectData) ?? ''}
                      min={get_html_field_type(field)==='number'?field.min:null}
                      max={get_html_field_type(field)==='number'?field.max:null}
                      checked={get_checked(field, objectData)}
                      onChange={(e) => { handleChange(e.target.value, field) }} 
                    />}
                    {field.type === 'longtext' && <textarea 
                      readOnly={field.name==='created'} 
                      name={field.name}
                      required={field.required}
                      className={get_html_field_classname(field) + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      type={get_html_field_type(field)}
                      value={get_field_value(field, objectData) ?? ''}
                      min={get_html_field_type(field)==='number'?field.min:null}
                      max={get_html_field_type(field)==='number'?field.max:null}
                      checked={get_checked(field, objectData)}
                      onChange={(e) => { handleChange(e.target.value, field) }} ></textarea>}
                    {field.type === 'uuid' && field.name !== 'uuid' && <AutocompleteInput
                      apiSubModel={get_field_apimodel(field, structureData)}
                      value={get_field_value(field, objectData) ?? ''}
                      autoCompleteClass={get_html_field_classname(field) + ' material-form-control' + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      field={field}
                      changeHandler={handleChange}
                      useLogUrl={useLogUrl}
                    />}
                    {field.type === 'uuid[]' && <MultiAutoComplete
                      apiSubModel={get_field_apimodel(field, structureData)}
                      fieldName={field.name}
                      initMultiValue={get_field_value(field, objectData) ?? ''}
                      isValid={get_field_isvalid(field,validation)}
                      field={field}
                      changeHandler={handleChange}
                      useLogUrl={useLogUrl}
                    />}
                    </div>
                    </React.Fragment>
                }) }
                </div>
              {/* {saved && <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-primary'><b>Salvato con successo!</b></p></div>
                </div>}
              {saveErr && <div className="row">
                  <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-danger'><b>{saveErr}</b></p></div>
              </div>} */}

        </div>}
    </div>
    </div>
    </>
  )
}

export default Newform
