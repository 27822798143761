import React, { useCallback, useRef } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { loadLoginFromLocalStorage, logIn, logOut, saveLoginInLocalStorage } from "./app/store";
import axios from 'axios';
import Home from './templates/Home';
import Redirector from './templates/Redirector';
import NotFound from './templates/404';
import { Fragment } from 'react';
import { toast } from 'react-toastify';

const App = () => {

  const user = useSelector((state)=> state.user)
  const dispatch = useDispatch()
  
  /*                                 /
  /  Refresh login timer management  /
  /                                 */
  const refreshTokenIntervalDuration = 30*1000 // 30 seconds
  const tokenResidualValidityAllowed = 60*1000 // 1 minute, renew attempt will be done at 5x of this 
  const refreshTokenInterval = useRef()

  /* Actual axios call to obtain a new token and refresh token */
  const renewToken = useCallback(async(refreshToken) => {
    const url = process.env.REACT_APP_LOCAL_AUTH_GET_TOKEN_URL
    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      params: {}
    }
    const postData = {
      'grant_type': 'refresh_token',
      'client_id': 'aaaui',
      'refresh_token': refreshToken
    }
    try{
      const response = await axios.post(url,postData,config)
      if(response.data.error){
          toast.error(response.data.error)
          dispatch(logOut())
      } else {
        return response.data
      }
    } catch (error){
      toast.error('Errore di rete in fase di rinnovo credenziali di accesso, si prega di ripetere il login')
    }
    return null
  },[dispatch])

  const executeTokenCheck = useCallback(() => {
    let now = (new Date()).getTime(),
        expiration = (new Date(user.expires)).getTime()
    if((expiration !== 0) && (expiration-now < tokenResidualValidityAllowed*5)) {
      if(user.refreshToken){
        renewToken(user.refreshToken,user.expires).then(
          (data) => {
            if(data && data.access_token){
              dispatch(logIn(
                { token: data.access_token, refreshToken: data.refresh_token, expires: data.expires_in }
                ))
              dispatch(saveLoginInLocalStorage())
            } else {
              console.log('New token not received, will log out')
              toast.error('Errore in fase di rinnovo delle credenziali di accesso, si prega di ripetere il login')
              dispatch(logOut())
            }
          }
        )
      } else {
        dispatch(logOut())
      }
    }
  },[dispatch, renewToken, tokenResidualValidityAllowed, user.expires, user.refreshToken])

  /* Starts refresh token interval */
  const startRefreshTokenInterval = useCallback(() => {
      refreshTokenInterval.current = setInterval(() => {
        executeTokenCheck()
      }, refreshTokenIntervalDuration)
  },[refreshTokenIntervalDuration,executeTokenCheck])

  /* Clears refresh token interval */
  const clearRefreshTokenInterval = useCallback(() => {
    clearInterval(refreshTokenInterval.current);
  },[refreshTokenInterval])

  /* On login/logout/renew token, manage refresh token interval */
  useEffect(()=>{
    if(!refreshTokenInterval.current && user.logged_in) {
      startRefreshTokenInterval()
    }
    if(refreshTokenInterval.current && user.logged_in) {
      clearRefreshTokenInterval()
      startRefreshTokenInterval()
    }
    if(refreshTokenInterval.current && !user.logged_in){
      clearRefreshTokenInterval()
    }
  },[refreshTokenInterval,user.logged_in,startRefreshTokenInterval,clearRefreshTokenInterval])
  
  /* On login of page load from a previous logged in state, check token validity */
  useEffect(()=>{
    if(user.logged_in){
      let now = (new Date()).getTime(),
            expiration = (new Date(user.expires)).getTime()
      if((expiration === 0) || (expiration-now < tokenResidualValidityAllowed)) {
        dispatch(logOut())
      }
    }
  },[user.logged_in,tokenResidualValidityAllowed,dispatch,user.expires]);

  /*                                    /
  / End refresh login timer management  /
  /                                    */

  useEffect(()=>{
    dispatch(loadLoginFromLocalStorage())
  });

  const page_config = [
    {nome_link:"Homepage",nome_icona:"home",url:'/home',apiModel:'product',nomeSing:"prodotto",nomePlur:"prodotti",enableSave:true,enableDelete:true},
    {nome_link:"Produttori",nome_icona:"person",url:'/brands',apiModel:'brand',nomeSing:"produttore",nomePlur:"produttori",enableSave:true,enableDelete:true},
    {nome_link:"Logs",nome_icona:"warning",url:'/errors',apiModel:'log',nomeSing:"log",nomePlur:"log",enableSave:false,enableDelete:false,useLogUrl:true},
    /* {nome_link:"API Reference",nome_icona:"summarize",url:'/apihelp',apiModel:'product',nomeSing:"api",nomePlur:"api",enableSave:false,enableDelete:false}
  */ ]
  
  /* Specific routing done in Centralbody */
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirector/>} />
        {
          page_config.map((page,index)=>(<Fragment key={index}>
            <Route path={page.url} element={
              <Home url={page.url} apiModel={page.apiModel} nomeSing={page.nomeSing} nomePlur={page.nomePlur} enableSave={page.enableSave} enableDelete={page.enableDelete} links={page_config} useLogUrl={page.useLogUrl} />
            } />
            <Route path={page.url+'/:id'} element={
              <Home url={page.url} apiModel={page.apiModel} nomeSing={page.nomeSing} nomePlur={page.nomePlur} enableSave={page.enableSave} enableDelete={page.enableDelete} links={page_config} useLogUrl={page.useLogUrl} />
            } />
          </Fragment>
          ))
        }
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
